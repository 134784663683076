import Vue from 'vue'
import Router from 'vue-router'
import Footer from './components/modules/FooterSection.vue'
Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // linkExactActiveClass: 'is-active',
  linkActiveClass: 'is-active',
  routes: [
    {
      path: '/liveSchool',
      name: 'liveTalkFrontView',
      components: {
        default: () => import('@/views/Frontend.vue'),
        footer: Footer
      },
      children: [
        {
          path: '/startClass/:id',
          name: 'startClass',
          component: () => import('@/pages/startClass.vue')
        }
      ]
    },
    {
      path: '/',
      name: '',
      components: {
        default: () => import('@/views/Backend.vue'),
        footer: Footer
      },
      children: [
        {
          path: '/line_notify/authorized',
          name: 'line_notify',
          component: () => import('@/pages/LineNotify.vue')
        },
        // {
        //   // bizTalk
        //   path: '/speaking',
        //   name: 'homePro',
        //   component: () => import('@/pages/HomePro.vue')
        // },
        {
          path: '/',
          name: 'home',
          component: () => import('@/pages/Home.vue')
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('@/pages/Login.vue')
        },
        // 個人資料管理
        {
          path: '/users/manage',
          name: 'account_add',
          component: () => import('@/pages/Manage.vue')
        },
        {
          path: '/students/manage',
          name: 'student_add',
          component: () => import('@/pages/Manage.vue')
        },
        {
          path: '/users/manage/:id?',
          name: 'account_edit',
          component: () => import('@/pages/Manage.vue')
        },
        {
          path: '/students/manage/:id?',
          name: 'student_edit',
          component: () => import('@/pages/Manage.vue')
        },
        {
          path: '/verify',
          name: 'verify',
          component: () => import('@/pages/EmailVerify.vue')
        },
        // 註冊
        {
          path: '/register',
          name: 'register',
          component: () => import('@/pages/Register.vue')
        },
        // 政策
        {
          path: '/policy',
          name: 'policy',
          component: () => import('@/pages/company/Policy.vue')
        },
        // 忘記密碼
        {
          path: '/forget_password',
          name: 'forgetPwd',
          component: () => import('@/pages/ForgetPwd.vue')
        },
        {
          path: '/forget_password_reset/:token?',
          name: 'forgetPwdReset',
          component: () => import('@/pages/ForgetPwd_change.vue')
        },
        {
          path: '/users',
          name: 'Users',
          component: () => import('@/pages/Users.vue')
        },
        // 帳號回收
        {
          path: '/users/recycle',
          name: 'usersRecycle',
          component: () => import('@/pages/UsersRecycle.vue')
        },
        // live talk
        {
          path: '/manage/liveTalk',
          name: 'courses',
          component: () => import('@/pages/CoursesTalk.vue')
        },
        // live teacher　報名進度
        {
          path: '/RegistrationProgress',
          name: 'RegistrationProgress',
          component: () => import('@/pages/RegistrationProgress.vue')
        },
        {
          path: '/manage/liveTeacher',
          name: 'CoursesTeacher',
          component: () => import('@/pages/CoursesTeacher.vue')
        },
        // live class　審核
        {
          path: '/liveClass/audit',
          name: 'CoursesTeacher_Audit',
          component: () => import('@/pages/CoursesTeacher_Audit.vue')
        },
        {
          path: '/manage/liveClass',
          name: 'CoursesClass',
          component: () => import('@/pages/CoursesClass.vue')
        },
        // live talk
        {
          path: '/manage/liveTalk/course/:id?',
          name: 'addTalkClass',
          component: () => import('@/pages/manage/AddClassTalk.vue')
        },
        // live talk 模板
        {
          path: '/manage/template/:id?',
          name: 'addTemplate',
          component: () => import('@/pages/manage/AddClassTemplate.vue')
        },
        // live talk 模板開課
        {
          path: '/manage/course/:id?',
          name: 'openTemplateCourse',
          component: () => import('@/pages/manage/AddClassTemplate.vue')
        },
        // live teacher
        {
          path: '/teacher_class/:status',
          name: 'addLiveTeacher',
          component: () => import('@/pages/manage/AddClassTeacher.vue')
        },
        // live class
        {
          path: '/manage/liveclass/course-editor/:courseId/:type?/:unitId?',
          name: 'courseEditor',
          component: () => import('@/pages/Course_Editor_Page.vue')
        },
        {
          path: '/videos/manage/:id?',
          name: 'addLiveClass',
          component: () => import('@/pages/manage/AddLiveClass.vue')
        },
        // 課前課後
        {
          path: '/manage/course-editor/:id?/:type?',
          name: 'AddPreReviewClass',
          component: () => import('@/pages/manage/AddPreReviewClass.vue')
        },
        {
          path: '/curriculum-changes',
          name: 'curriculum_changes',
          component: () => import('@/pages/CurriculumChanges.vue')
        },
        {
          path: '/class-list',
          name: 'class_list',
          component: () => import('@/pages/ClassList.vue')
        },
        // 模板
        {
          path: '/template-courses',
          name: 'course_template',
          component: () => import('@/pages/CourseTemplate.vue')
        },
        {
          path: '/manage/categories',
          name: 'classification',
          component: () => import('@/pages/Classification.vue')
        },
        {
          path: '/learn_record/list',
          name: 'learn_record_list',
          component: () => import('@/pages/Learn_recordList.vue')
        },
        {
          path: '/learn_record/student/:id?',
          name: 'learn_record_student',
          component: () => import('@/pages/Learn_record_student.vue')
        },
        {
          path: '/learn_record/hr/:id?',
          name: 'Learn_record_hr',
          component: () => import('@/pages/Learn_record_hr.vue')
        },
        {
          path: '/teaching_records',
          name: 'Teaching_records',
          component: () => import('@/pages/Teach_recordList.vue')
        },
        {
          path: '/teaching_record/:id?',
          name: 'Teaching_record',
          component: () => import('@/pages/Teaching_records.vue')
        },
        {
          path: '/reservations/reserve_list/:role',
          name: 'Reservations',
          component: () => import('@/pages/Book_courseDirector.vue')
        },
        {
          path: '/reservations/admin',
          name: 'book_courseAdmin',
          component: () => import('@/pages/Book_courseAdmin.vue')
        },
        {
          path: '/point/:id?',
          name: 'point',
          component: () => import('@/pages/Point.vue')
        },
        {
          path: '/point_list/:id?',
          name: 'pointList',
          component: () => import('@/pages/PointList.vue')
        },
        {
          path: '/serial_number',
          name: 'serial_number',
          component: () => import('@/pages/Serial_number.vue')
        },
        {
          path: '/announce/:mode?',
          name: 'announce',
          component: () => import('@/pages/Announce.vue')
        },
        {
          path: '/info',
          name: 'info',
          component: () => import('@/pages/Info.vue')
        },
        {
          path: '/my_courses',
          name: 'my_courses',
          component: () => import('@/pages/MyClass.vue')
        },
        {
          path: '/students',
          name: 'students',
          component: () => import('@/pages/Students.vue')
        },
        {
          path: '/sectional_drawing/:id?',
          name: 'SectionalDrawing',
          component: () => import('@/pages/Sectional_drawing.vue')
        },
        {
          path: '/booking/add/:role?',
          name: 'AddBooking',
          // component: () => process.env.NODE_ENV === 'production' ? import('@/pages/manage/AddBooking.vue') : import('@/pages/manage/AddBooking_dev.vue')
          component: () => import('@/pages/manage/AddBooking.vue')
        },
        {
          path: '/AddApply/add',
          name: 'AddApply',
          component: () => import('@/pages/manage/AddApply.vue')
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@/pages/Account.vue')
        },
        {
          path: '/course_setting',
          name: 'course_setting',
          component: () => import('@/pages/Course_setting.vue')
        },
        {
          path: '/selectLesson',
          name: 'SelectLesson',
          component: () => import('@/pages/SelectLesson.vue')
        },
        {
          path: '/points_trading/:id?',
          name: 'pointsTrading',
          component: () => import('@/pages/PointsTrading.vue')
        },
        {
          path: '/meeting',
          name: 'meeting',
          component: () => import('@/pages/Meeting.vue')
        },
        {
          path: '/meeting/manage/:id?',
          name: 'addMeeting',
          component: () => import('@/pages/manage/AddMeeting.vue')
        },
        {
          path: '/index_manage',
          name: 'IndexManage',
          component: () => import('@/pages/IndexManage.vue')
        },
        {
          path: '/ads/add',
          name: 'AddBanner',
          component: () => import('@/pages/manage/AddBanner.vue')
        },
        // 學生預約
        {
          path: '/courses/:type',
          name: 'bookingClass',
          component: () => import('@/pages/booking/CoursesList.vue')
        },
        {
          path: '/booking_class/talk/:id',
          name: 'classPreviewTalk',
          component: () => import('@/pages/booking/CoursesDetail.vue')
        },
        {
          path: '/booking_class/class/:id',
          name: 'classPreview',
          component: () => import('@/pages/booking/CoursesDetail.vue')
        },
        {
          path: '/booking_class/teacher/:id',
          name: 'classPreviewTeacher',
          component: () => import('@/pages/booking/CoursesDetail.vue')
        },
        // 督導
        {
          path: '/branch',
          name: 'branch',
          component: () => import('@/pages/Branch.vue')
        },
        // company
        {
          path: '/teachers',
          name: 'teachers',
          component: () => import('@/pages/company/teachers.vue')
        },
        {
          path: '/manual',
          name: 'manual',
          component: () => import('@/pages/company/manual.vue')
        },
        {
          path: '/qa',
          name: 'qa',
          component: () => import('@/pages/company/qa.vue')
        },
        {
          path: '/software',
          name: 'software',
          component: () => import('@/pages/company/software.vue')
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    window.scrollTo({ 'top': 0 })
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})
export default router;